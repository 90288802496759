<template>
  <v-dialog v-model="dialogView" persistent max-width="800px">
    <v-card class="pa-0" elevation="2">
      <v-card-text class="pb-0">
        <v-form ref="form" lazy-validation>
          <v-container fluid>
            <v-row>
              <v-col cols="12" class="pa-0">
                <div>
                  <h3>{{ $vuetify.lang.t("$vuetify.FrieghtView") }}</h3>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions class="pb-8">
        <v-spacer />
        <v-btn color="red darken-2" outlined @click="cancelForm">{{
          $vuetify.lang.t("$vuetify.cancel")
        }}</v-btn>
        <v-btn color="green darken-2" @click="download">{{
          $vuetify.lang.t("$vuetify.download")
        }}</v-btn>
        <v-spacer v-if="$vuetify.breakpoint.xsOnly" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import jsPDF from "jspdf";
import "jspdf-autotable";
export default {
  name: "ViewDialog",
  props: { dialogView: Boolean, myObj: Object },
  data() {
    return {
      heading: "Shipping Record",
    };
  },
  methods: {
    cancelForm() {
      this.$emit("update:dialogView", false);
    },
    reset() {
      this.$refs.form.reset();
    },
    download() {
      this.$toast.success("Downloaded", { timeout: 1500 });
      const columns = [
        { title: "Order" },
        { title: "Delivered", styles: { halign: "center", cellWidth: 2.25 } },
        { title: "Unit" },
        { title: "Sender" },
        { title: "Customer" },
        { title: "Est Arv Time" },
        { title: "Est Arv Time 1" },
        { title: "Est Arv Time 2" },
        { title: "Delivery Date" },
        { title: "Actual Arrival Time" },
      ];
      const doc = new jsPDF({
        orientation: "landscape",
        unit: "in",
        format: "letter",
      });
      doc.setFontSize(16).text(this.heading, 0.5, 1.0);
      doc.setLineWidth(0.01).line(0.5, 1.1, 10.4, 1.1);
      doc.autoTable({
        styles: { fillColor: [76, 175, 80], fontSize: 8.25 },
        head: [columns],
        body: [this.datapdf()],
        margin: { left: 0.1, top: 1.25, right: 0.1 },
      });
      doc.save("Shipping Record.pdf");
    },
    datapdf() {
      var customer_contact = "";
      if (this.myObj.customer_info.customer_id != "") {
        customer_contact = this.myObj.customer_info.contact;
      }

      var data = [
        { title: this.myObj.po },
        { title: this.myObj.send_goods[0].name },
        {
          title: this.myObj.send_goods[0].order_number,
          styles: { halign: "center" },
        },
        { title: this.myObj.user_info.name },
        { title: customer_contact },
        { title: this.myObj.plan_receive, styles: { halign: "center" } },
        { title: this.myObj.plan_receive_r1, styles: { halign: "center" } },
        { title: this.myObj.plan_receive_r2, styles: { halign: "center" } },
        { title: this.myObj.send_date, styles: { halign: "center" } },
        { title: this.myObj.real_receive, styles: { halign: "center" } },
      ];
      return data;
    },
  },
};
</script>